/** for desktop view */
import copaMundial1 from '../assets/images/copa-mundial-slider-1.png';
import copaMundial2 from '../assets/images/copa-mundial-slider-2.png';
import copaMundial3 from '../assets/images/copa-mundial-slider-3.png';
import copaMundial4 from '../assets/images/copa-mundial-slider-4.png';
import viewPoint1 from '../assets/images/view-point-slider-1.png';
import viewPoint2 from '../assets/images/view-point-slider-2.png';
import worldCupRoom1 from '../assets/images/world-cup-room-slider-1.png';
import worldCupRoom2 from '../assets/images/world-cup-room-slider-2.png';
import worldCupRoom3 from '../assets/images/world-cup-room-slider-3.png';
import worldCupRoom4 from '../assets/images/world-cup-room-slider-4.png';

/** for mobile view */
import copaMundial1M from '../assets/images/copa-mundial-slider-m-1.png';
import copaMundial2M from '../assets/images/copa-mundial-slider-m-2.png';
import copaMundial3M from '../assets/images/copa-mundial-slider-m-3.png';
import copaMundial4M from '../assets/images/copa-mundial-slider-m-4.png';
import viewPoint1M from '../assets/images/view-point-slider-m-1.png';
import viewPoint2M from '../assets/images/view-point-slider-m-2.png';
import worldCupRoom1M from '../assets/images/world-cup-room-slider-m-1.png';
import worldCupRoom2M from '../assets/images/world-cup-room-slider-m-2.png';
import worldCupRoom3M from '../assets/images/world-cup-room-slider-m-3.png';
import worldCupRoom4M from '../assets/images/world-cup-room-slider-m-4.png';

/** for bottom venue map(svg background) */
import venueHireMapBg from '../assets/images/venue-hire-map-bg.png';


export const _VENUE_HIRE_DATA = {
    copaMundial: {
        title: "Copa Mundial",
        description: "Our largest room is suited to meetings, workshops and presentations with access to our terrace area providing an outdoor space overlooking the #Merky FC football pitch. The room can be set up in a variety of formats with our table, chairs and large in built TV screen. ",
        gallery: [
            {
                default: copaMundial1,
                list: [
                    { media: '(max-width: 768px)', srcset: copaMundial1M },
                    { media: '(min-width: 769px)', srcset: copaMundial1 }
                ]
            },
            {
                default: copaMundial2,
                list: [
                    { media: '(max-width: 768px)', srcset: copaMundial2M },
                    { media: '(min-width: 769px)', srcset: copaMundial2 }
                ]
            },
            {
                default: copaMundial3,
                list: [
                    { media: '(max-width: 768px)', srcset: copaMundial3M },
                    { media: '(min-width: 769px)', srcset: copaMundial3 }
                ]
            },
            {
                default: copaMundial4,
                list: [
                    { media: '(max-width: 768px)', srcset: copaMundial4M },
                    { media: '(min-width: 769px)', srcset: copaMundial4 }
                ]
            }
        ],
        table: [
            {
                title: "Space",
                values: ["11.1m x 5.3m", "59 m2", ""]
            },
            {
                title: "Facilities",
                values: ["Air Conditioning", "WiFi", "Smart TV"]
            },
            {
                title: "Capacity",
                values: ["65 Seated", "90 Standing", ""]
            },
            {
                title: "Miscellaneous",
                values: ["Optional Tables and Chairs", "Outdoor Terrace(4.1m x 5.8m)", ""]
            }
        ]
    },
    viewPoint: {
        title: 'View Point',
        description: 'This intimate room has a great vista over the #Merky FC football pitch and is good for small meetings. It also works well as a break out room and/or green room when booked with the Copa Mundial room.',
        gallery: [
            {
                default: viewPoint1,
                list: [
                    { media: '(max-width: 768px)', srcset: viewPoint1M },
                    { media: '(min-width: 769px)', srcset: viewPoint1 }
                ]
            },
            {
                default: viewPoint2,
                list: [
                    { media: '(max-width: 768px)', srcset: viewPoint2M },
                    { media: '(min-width: 769px)', srcset: viewPoint2 }
                ]
            }
        ],
        table: [
            {
                title: "Space",
                values: ["1.8m x 3.8m", "7 m2", ""]
            },
            {
                title: "Facilities",
                values: ["Air Conditioning", "WiFi", "Smart TV"]
            },
            {
                title: "Capacity",
                values: ["2 Seated", "5 Standing", ""]
            },
            {
                title: "Miscellaneous",
                values: ["Optional Tables and Chairs", "", ""]
            }
        ]
    },
    worldCupRoom: {
        title: 'World Cup Room',
        description: 'This room has a fully mirrored wall and ballet barre and is perfect to host dance or fitness classes. It can also be set up in a variety of formats with our table, chairs and large in built TV screen.',
        gallery: [
            {
                default: worldCupRoom1,
                list: [
                    { media: '(max-width: 768px)', srcset: worldCupRoom1M },
                    { media: '(min-width: 769px)', srcset: worldCupRoom1 }
                ]
            },
            {
                default: worldCupRoom2,
                list: [
                    { media: '(max-width: 768px)', srcset: worldCupRoom2M },
                    { media: '(min-width: 769px)', srcset: worldCupRoom2 }
                ]
            },
            {
                default: worldCupRoom3,
                list: [
                    { media: '(max-width: 768px)', srcset: worldCupRoom3M },
                    { media: '(min-width: 769px)', srcset: worldCupRoom3 }
                ]
            },
            {
                default: worldCupRoom4,
                list: [
                    { media: '(max-width: 768px)', srcset: worldCupRoom4M },
                    { media: '(min-width: 769px)', srcset: worldCupRoom4 }
                ]
            }
        ],
        table: [
            {
                title: "Space",
                values: ["7.9m x 5.5m", "43 m2", ""]
            },
            {
                title: "Facilities",
                values: ["Air Conditioning", "WiFi", "Smart TV"]
            },
            {
                title: "Capacity",
                values: ["40 Seated", "65 Standing", ""]
            },
            {
                title: "Miscellaneous",
                values: ["Mirrored Wall with Ballet Barre", "Optional Tables and Chairs", ""]
            }
        ]
    }
};

export const _venueHireMapBg = venueHireMapBg;