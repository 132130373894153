import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import './Home.component.scss';
import slider1 from '../../assets/images/home-slider-1.png';
import slider2 from '../../assets/images/home-slider-2.png';
import slider3 from '../../assets/images/home-slider-3.png';
import slider4 from '../../assets/images/home-slider-4.png';
import homeVenueMap from '../../assets/images/home-venue-map.jpg';

import { _bookingTypes } from '../../constants/booking-types';

const sliderImages = [slider1, slider2, slider3, slider4];

function Home() {

    const bookingTypes = [..._bookingTypes];

    /** component will-mount */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /** image gallery slider */
    useEffect(() => {
        let sliderImpetus;
        const sliderManager = () => {
            const slider = document.querySelector(".image-slider-container");

            slider.style.cursor = "-webkit-grab";
            slider.addEventListener(
                "mousedown",
                () => (slider.style.cursor = "-webkit-grabbing"),
                true
            );
            window.addEventListener(
                "mouseup",
                () => (slider.style.cursor = "-webkit-grab"),
                true
            );

            sliderImpetus = new window.Impetus({
                source: slider,
                boundX: [0, slider.scrollWidth - slider.clientWidth],
                multiplier: -1,
                update(x) {
                    this.scrollLeft = x;
                }
            });
        }
        /** call at once on component load, use after on window.resize to reinitialize sliderManager */
        sliderManager();
        let timeOutFunctionId;
        const resetSliderManager = () => {
            clearTimeout(timeOutFunctionId);
            timeOutFunctionId = setTimeout(sliderManager, 500);
        }

        window.addEventListener('resize', resetSliderManager);
        return () => {
            sliderImpetus.destroy();
            window.removeEventListener('resize', resetSliderManager);
        }
    }, []);


    const navigate = useNavigate();
    const onNavigateToBook = (category) => {
        navigate('booking', {
            state: category.type
        });
    }

    return (
        <div className="home-wrapper">

            <div className='hero-section'>
                <img className='hero-img' alt='' />
            </div>

            <div className='welcome-section'>
                <div className='welcome-section-img-wrapper'>
                    <img className='welcome-section-img' alt='' />
                </div>
            </div>

            <div className="image-slider-container">
                <div className="image-slider-wrapper">
                    {
                        (sliderImages?.length > 0) && sliderImages.map((imgSrc, i) => {
                            return (
                                <div className="image-item" key={i}>
                                    <img className='image-item-img' src={imgSrc} alt='' draggable='false' />
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className='home-about-us-wrapper'>
                <h4 className='about-us-title'>about us</h4>
                <p className='about-us-description'>
                    #MERKY FC HQ IS A FOOTBALL AND COMMUNITY CENTRE IN SELHURST, DESIGNED TO NURTURE THE VOICES OF TOMORROW. PURPOSE-BUILT WITH A FULL SIZE 3G PITCH, RECORDING STUDIO AND GAMING ROOM, IT’S A SAFE SPACE FOR ALL TO EXPRESS THEMSELVES.
                    <br /><br />
                    RAN BY COMMUNITIES FIRST WHO ARE AN ORGANISATION THAT SUPPORT LOCAL COMMUNITIES AND YOUTH, ADIDAS AND #Merky FC WILL ACTIVATE IN THE SPACE THROUGHOUT THE YEAR, USING SPORT AND MUSIC AS THEIR MAIN FOCUS.
                </p>
            </div>


            <div className='home-booking-wrapper'>
                {
                    bookingTypes.map((bookItem, i) => {
                        return (
                            <div className='home-booking-item' key={i} onClick={() => onNavigateToBook(bookItem)}>
                                {/* <img className='home-booking-item-img' src={bookItem.img} alt='' /> */}
                                <picture>
                                    {bookItem.image?.list?.map((sourceItem, ii) => {
                                        return <source key={`${i}${ii}`} media={sourceItem?.media} srcSet={sourceItem?.srcset} />
                                    })}
                                    <img className='home-booking-item-img' srcSet={bookItem.image?.default} draggable="false" alt="Chris standing up holding his daughter Elva" />
                                </picture>
                                <div className='home-booking-item-details'>
                                    <h4 className='home-booking-item-title'>{bookItem.title}</h4>
                                    {/* <p className='home-booking-item-description'>{bookItem.description}</p> */}
                                </div>
                            </div>
                        );
                    })
                }
                {/* style={{backgroundImage: `url(${bookPitchM})`}} */}
                {/* <div className='home-booking-item'>
                    <img className='home-booking-item-img' src={bookPitchM} alt='' />
                    <div className='home-booking-item-details'>
                        <h4 className='home-booking-item-title'>book a pitch</h4>
                        <p className='home-booking-item-description'>Lorem ipsum dolor san e mono exrer ipsum dolor san e mono exrer ipsum dolor san e mono exrer ipsum dolor san e mono exrer </p>
                    </div>
                </div> */}
                {/* <div className='home-booking-item'>
                    <h4 className='home-venue-hire-link'>
                        venue hire coming soon
                    </h4>
                </div> */}
            </div>


            <div className='home-venue-map-wrapper'>
                <div className='home-venue-map'>
                    <img className='home-venue-map-img' src={homeVenueMap} draggable='false' alt='mfchub venue' />
                </div>
            </div>


            <div className='home-address-container'>
                <div className='footer-logo'></div>

                <div className='home-address-wrapper'>
                    <div className='home-address-item'>
                        <h4 className='address-label'>opening times</h4>
                        <h4 className='address-details'>Mon - Sun<br />8am - 10pm</h4>
                    </div>
                    <div className='home-address-divider'></div>
                    <div className='home-address-item'>
                        <h4 className='address-label'>location</h4>
                        <h4 className='address-details'>Dagnall Park,<br />London,<br />SE25 5PH</h4>
                    </div>
                    <div className='home-address-divider'></div>
                    <div className='home-address-item'>
                        <h4 className='address-label'>#merky fc hq</h4>
                        <h4 className='address-details'>CHANGING <br />THE GAME</h4>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home;