import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './VenueEnquire.component.scss';

import AppContext from '../../context/app/app.context';
import { saveVenueEnquire } from '../../services/venue-enquire.service';
import Regexp from '../../constants/regexp';


const VenueEnquire = () => {

    const { onModalsClose, modalState } = useContext(AppContext);
    const navigate = useNavigate();
    const [toDayDate, setToDayDate] = useState();
    /** component will-mount */
    useEffect(() => {
        // if (!modalState) {
        //     onModalsClose();
        // }

        const date = new Date();
        setToDayDate(`${date.getFullYear()}-${('0' + (date.getMonth() + 1))?.slice(-2)}-${('0' + date.getDate())?.slice(-2)}`);
        // eslint-disable-next-line
    }, []);

    /** managing modal component to hide and release overflow for body */
    const onModalClose = () => {
        onModalsClose();
    }

    const contactUsDefaultValues = {
        fullName: '',
        email: '',
        contactNumber: '',
        companyName: '',
        time: {
            date: '',
            startTime: '',
            endTime: ''
        },
        rooms: [],
        reasonForHire: '',
        numberOfGuests: null,
        tAndCs: false
    };
    const { register, handleSubmit, formState: { errors, isDirty, isValid }, getValues, setValue, setError, clearErrors } = useForm({
        mode: 'all',
        defaultValues: contactUsDefaultValues
    });
    const [isRequestAlive, setIsRequestAlive] = useState(false);
    const [responseErrorMsg, setResponseErrorMsg] = useState('');

    const onVenueEnquireSubmit = (data, e) => {
        e.preventDefault();
        try {
            if (!isValid || !isDirty || isRequestAlive) {
                return;
            } else {
                setIsRequestAlive(true);
                const { tAndCs, ...body } = data;
                saveVenueEnquire(body).then((res) => {
                    if (res.data.code === 'OK') {
                        /** re-set form error message */
                        setResponseErrorMsg('');
                        setIsReqSubmitted(true);
                    }
                    setIsRequestAlive(false);
                }).catch((err) => {
                    setIsRequestAlive(false);
                    if (err.response.data.code === "E_UNAUTHORIZED") {
                        setResponseErrorMsg("Login first before submit an enquiry.");
                    } else { setResponseErrorMsg(err.response.data.message); }
                    console.log('Error while submitting venue enquire', err);
                });
            }
        } catch (error) {
            setIsRequestAlive(false);
            console.log('Error while submitting venue enquire', error);
        }
    }

    const onHandleInvalidFormSubmission = (errors, e) => {
        e?.preventDefault();
        const selectedRooms = venueRoomList.filter(room => room.selected);
        if (selectedRooms.length === 0) {
            setError("rooms", { message: 'At least one room must be selected.' });
        }
    }

    const onTimeInputsBlur = (e) => {
        switch (e.target.name) {
            case "time.date":
                if (e.target.value) {
                    const toDay = new Date(...toDayDate?.split("-")?.map((v, i) => i === 1 ? (parseInt(v) - 1) : v), 0, 0, 0);
                    const inputDate = new Date(...e.target.value.split("-")?.map((v, i) => i === 1 ? (parseInt(v) - 1) : v), 0, 0, 0);
                    if (inputDate.getTime() < toDay.getTime()) {
                        setError('time.date', { type: 'invalid', message: 'Date must be future date, at least today date.' });
                    }
                }
                break;
            case "time.startTime":
                if (e.target.value) {
                    if (getValues('time.endTime')) {
                        const [sh, sm] = e.target.value?.split(":")?.map(v => parseInt(v));
                        const [eh, em] = getValues('time.endTime')?.split(":")?.map(v => parseInt(v));
                        if (eh < sh || (sh >= eh && em <= sm)) {
                            setError('time.endTime', { type: 'invalid', message: 'End Time must be greater than Start Time.' });
                        } else { clearErrors('time.endTime') }
                    }
                }
                break;
            case "time.endTime":
                if (e.target.value) {
                    const [sh, sm] = getValues('time.startTime')?.split(":")?.map(v => parseInt(v));
                    const [eh, em] = e.target.value?.split(":")?.map(v => parseInt(v));
                    if (eh < sh || (sh >= eh && em <= sm)) {
                        setError('time.endTime', { type: 'invalid', message: 'End Time must be greater than Start Time.' });
                    }
                }
                break;

            default:
                break;
        }
    }

    const [isReqSubmitted, setIsReqSubmitted] = useState(false);
    const onBackToHome = () => {
        onModalsClose();
        navigate('/');
        return;
    }


    const [venueRoomList, setVenueRoomList] = useState([
        {
            name: 'copa_mundial',
            label: 'Copa Mundial',
            selected: false
        },
        {
            name: 'view_point',
            label: 'View Point',
            selected: false
        },
        {
            name: 'world_cup_room',
            label: 'World Cup Room',
            selected: false
        }
    ]);

    const onChangeVenueRoom = (index) => {
        const updatedRooms = [...venueRoomList];
        updatedRooms[index].selected = !updatedRooms[index].selected;
        setVenueRoomList(updatedRooms);

        // Update the 'rooms' field in form with selected values
        const selectedRooms = updatedRooms.filter(room => room.selected).map(room => room.name);
        setValue("rooms", selectedRooms);  // Update the form state
        if (selectedRooms.length === 0) {
            setError("rooms", { message: 'At least one room must be selected.' });
        } else {
            clearErrors("rooms");
        }
    }


    return (
        <div className='venue-enq-container'>

            {!isReqSubmitted &&
                <div className='venue-enq-wrapper'>
                    <div className='venue-enq-header'>
                        <div className='venue-enq-modal-close-btn' onClick={onModalClose}>
                            <svg viewBox="0 0 32 32" fill="none">
                                <path d="M32 4.03186L27.9681 0L16.0116 11.9566L4.03186 0L0 4.03186L11.9566 16.0116L0 27.9681L4.03186 32L16.0116 20.0434L27.9681 32L32 27.9681L20.0434 16.0116L32 4.03186Z" fill="#1E1E1E" />
                            </svg>
                        </div>
                        <h4 className='venue-enq-title mb'>venue hire</h4>
                        <p className='venue-enq-description'>Please fill in the form below to book.</p>
                    </div>

                    <form className='contact-us-form' onSubmit={handleSubmit((data, e) => onVenueEnquireSubmit(data, e), onHandleInvalidFormSubmission)} autoComplete="off">
                        <div className='venue-enq-common-wrapper'>
                            <h4 className='venue-enq-title mb'>contact</h4>
                            <div className='venue-enq-common-form-inputs-wrap'>
                                <div className='venue-enq-common-form-item'>
                                    <input className='venue-enq-common-form-item-input-field' type='text' placeholder='full name'
                                        {...register('fullName', {
                                            required: 'This field is required.'
                                        })} />
                                    {
                                        errors.fullName && (<h4 className='venue-enq-form-item-input-err-msg'>
                                            {errors.fullName.message}
                                        </h4>)
                                    }
                                </div>
                                <div className='venue-enq-common-form-item'>
                                    <input className='venue-enq-common-form-item-input-field' type='email' placeholder='email address'
                                        {...register('email', {
                                            required: 'This field is required.',
                                            pattern: {
                                                value: Regexp._EMAIL,
                                                message: 'Please enter a valid e-mail address.'
                                            }
                                        })} />
                                    {
                                        errors.email && (<h4 className='venue-enq-form-item-input-err-msg'>
                                            {errors.email.message}
                                        </h4>)
                                    }
                                </div>
                                <div className='venue-enq-common-form-item'>
                                    <input className='venue-enq-common-form-item-input-field' type='text' minLength={10} maxLength={14} placeholder='contact number'
                                        {...register('contactNumber', {
                                            required: 'This field is required.',
                                            pattern: {
                                                value: Regexp._MOBILE_NUMBER,
                                                message: 'Please enter a valid contact number'
                                            }
                                        })} />
                                    {
                                        errors.contactNumber && (<h4 className='venue-enq-form-item-input-err-msg'>
                                            {errors.contactNumber.message}
                                        </h4>)
                                    }
                                </div>
                                <div className='venue-enq-common-form-item'>
                                    <input className='venue-enq-common-form-item-input-field' type='text' placeholder='company name (If applicable)'
                                        {...register('companyName')} />
                                    {
                                        errors.companyName && (<h4 className='venue-enq-form-item-input-err-msg'>
                                            {errors.companyName.message}
                                        </h4>)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='venue-enq-common-wrapper'>
                            <h4 className='venue-enq-title mb'>room selection</h4>
                            <div className='venue-room-selection-wrapper'>
                                {
                                    venueRoomList.map((venueRoom, i) => {
                                        return (
                                            <div className='vr-selection-checkbox-wrap' key={i}>
                                                <div className='vr-selection-checkbox-icon-wrap' onClick={() => onChangeVenueRoom(i)}>
                                                    {venueRoom.selected ?
                                                        <svg className='vr-selection-checkbox-icon' width="34" height="34" viewBox="0 0 162 162" fill="none">
                                                            <g id="Group">
                                                                <path id="Vector" d="M132.319 0H29.6772L0 29.6772V132.319L29.6772 161.933H132.319L161.933 132.319V29.6772L132.319 0Z" fill="#DAFF00" />
                                                                <path id="Vector_2" d="M123.687 44.5474H115.243L65.4032 94.3875L46.8156 75.7368H38.2463L33.0796 80.9036V89.3468L61.1186 117.386H69.6248L128.664 58.2203L128.727 56.9602C128.79 54.6288 128.79 53.3056 128.79 51.0373V49.7141L123.687 44.5474Z" fill="#282727" />
                                                            </g>
                                                        </svg> :
                                                        <svg className='vr-selection-checkbox-icon' width="34" height="34" viewBox="0 0 34 34" fill="none">
                                                            <g id="Group 131">
                                                                <path id="Vector" d="M27.7592 0H6.25409L0 6.25409V27.7592L6.25409 34H27.7592L34 27.7592V6.25409L27.7592 0ZM8.34762 28.9119L5.08808 25.6524V8.34762L8.34762 5.08808H25.6391L28.8987 8.34762V25.6391L25.6391 28.8987H8.34762V28.9119Z" fill="#000000" />
                                                            </g>
                                                        </svg>
                                                    }
                                                </div>
                                                <h4 className='vr-selection-checkbox-label'>{venueRoom.label}</h4>
                                            </div>
                                        );
                                    })
                                }
                                {
                                    errors.rooms && (<h4 className='venue-enq-form-item-input-err-msg'>
                                        {errors.rooms.message}
                                    </h4>)
                                }
                            </div>
                        </div>

                        <div className='venue-enq-common-wrapper'>
                            <h4 className='venue-enq-title mb'>venue hire</h4>
                            <p className='venue-enq-description'>Please remember to incorporate setup and derig time.</p>
                            <div className='venue-enq-common-form-inputs-wrap'>
                                <div className='venue-enq-common-form-item'>
                                    <input className='venue-enq-common-form-item-input-field date-time-input extra-height' type='date' placeholder='date of hire'
                                        {...register('time.date', {
                                            required: 'This field is required.'
                                        })}
                                        min={toDayDate}
                                        onBlur={onTimeInputsBlur} />
                                    {
                                        errors.time?.date && (<h4 className='venue-enq-form-item-input-err-msg'>
                                            {errors.time.date.message}
                                        </h4>)
                                    }
                                </div>
                                <div className='venue-enq-common-form-item'>
                                    <input className='venue-enq-common-form-item-input-field date-time-input extra-height' type='time' placeholder='start time of hire'
                                        {...register('time.startTime', {
                                            required: 'This field is required.'
                                        })}
                                        onBlur={onTimeInputsBlur} />
                                    {
                                        errors.time?.startTime && (<h4 className='venue-enq-form-item-input-err-msg'>
                                            {errors.time.startTime.message}
                                        </h4>)
                                    }
                                </div>
                                <div className='venue-enq-common-form-item'>
                                    <input className='venue-enq-common-form-item-input-field date-time-input extra-height' type='time' placeholder='end time of hire'
                                        {...register('time.endTime', {
                                            required: 'This field is required.'
                                        })}
                                        onBlur={onTimeInputsBlur} />
                                    {
                                        errors.time?.endTime && (<h4 className='venue-enq-form-item-input-err-msg'>
                                            {errors.time.endTime.message}
                                        </h4>)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='venue-enq-common-wrapper'>
                            {/* <h4 className='venue-enq-title mb'>a/v requirements</h4>
                            <div className='venue-enq-common-form-textarea-wrap mb'>
                                <textarea className='venue-enq-common-form-textarea' placeholder='Insert message here...'
                                    {...register('aOrVRequirements', {
                                        required: 'This field is required.'
                                    })} />
                                {
                                    errors.aOrVRequirements && (<h4 className='venue-enq-form-item-input-err-msg'>
                                        {errors.aOrVRequirements.message}
                                    </h4>)
                                }
                            </div> */}

                            <h4 className='venue-enq-title mb'>reason for hire</h4>
                            <div className='venue-enq-common-form-textarea-wrap mb'>
                                <textarea className='venue-enq-common-form-textarea' placeholder='Insert message here...'
                                    {...register('reasonForHire', {
                                        required: 'This field is required.'
                                    })} />
                                {
                                    errors.reasonForHire && (<h4 className='venue-enq-form-item-input-err-msg'>
                                        {errors.reasonForHire.message}
                                    </h4>)
                                }
                            </div>

                            <h4 className='venue-enq-title mb'>number of guests</h4>
                            <div className='venue-enq-common-form-item'>
                                <input className='venue-enq-common-form-item-input-field' type='number' name='fullName' min='1' placeholder='number of guests'
                                    {...register('numberOfGuests', {
                                        required: 'This field is required.'
                                    })} />
                                {
                                    errors.numberOfGuests && (<h4 className='venue-enq-form-item-input-err-msg'>
                                        {errors.numberOfGuests.message}
                                    </h4>)
                                }
                            </div>
                        </div>

                        <div className='venue-enq-common-wrapper'>
                            <div>
                                <div className='venue-enq-tcs-wrapper'>
                                    <div className='venue-enq-tcs-checkbox'>
                                        <label htmlFor='tAndCs'>
                                            {getValues('tAndCs') ?
                                                <svg className='venue-enq-tcs-checkbox-icon' width="34" height="34" viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="Group">
                                                        <path id="Vector" d="M132.319 0H29.6772L0 29.6772V132.319L29.6772 161.933H132.319L161.933 132.319V29.6772L132.319 0Z" fill="#DAFF00" />
                                                        <path id="Vector_2" d="M123.687 44.5474H115.243L65.4032 94.3875L46.8156 75.7368H38.2463L33.0796 80.9036V89.3468L61.1186 117.386H69.6248L128.664 58.2203L128.727 56.9602C128.79 54.6288 128.79 53.3056 128.79 51.0373V49.7141L123.687 44.5474Z" fill="#282727" />
                                                    </g>
                                                </svg> :
                                                <svg className='venue-enq-tcs-checkbox-icon' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M27.7592 0H6.25409L0 6.25409V27.7592L6.25409 34H27.7592L34 27.7592V6.25409L27.7592 0ZM8.34762 28.9119L5.08808 25.6524V8.34762L8.34762 5.08808H25.6391L28.8987 8.34762V25.6391L25.6391 28.8987H8.34762V28.9119Z" fill="#282727" />
                                                </svg>
                                            }
                                        </label>
                                        <input className='venue-enq-tcs-checkbox-input' type='checkbox' {...register('tAndCs', { required: 'Please read and agree the Privacy Policy to proceed.' })} name='tAndCs' id='tAndCs' />
                                    </div>
                                    <h4 className='venue-enq-tcs-text'>i’ve read and agree with the <a className='venue-enq-tcs-link' href='/privacy-policy' target='_blank'>privacy policy</a>.</h4>
                                </div>
                                {
                                    errors.tAndCs && (<h4 className='venue-enq-form-item-input-err-msg'>
                                        {errors.tAndCs.message}
                                    </h4>)
                                }
                                {responseErrorMsg && (<h4 className='venue-enq-form-item-input-err-msg'>
                                    {responseErrorMsg}
                                </h4>)}
                            </div>

                            <div className='venue-enq-action-wrap'>
                                <button className='venue-enq-submit-btn' type='submit' disabled={isRequestAlive}>submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            }

            {isReqSubmitted &&
                <div className='venue-req-sbm-wrapper'>
                    <div className='venue-req-sbm-modal-close-btn' onClick={onModalClose}>
                        <svg viewBox="0 0 32 32" fill="none">
                            <path d="M32 4.03186L27.9681 0L16.0116 11.9566L4.03186 0L0 4.03186L11.9566 16.0116L0 27.9681L4.03186 32L16.0116 20.0434L27.9681 32L32 27.9681L20.0434 16.0116L32 4.03186Z" fill="#1E1E1E" />
                        </svg>
                    </div>

                    <div className='venue-req-sbm-poster-wrapper'>
                        <img className='venue-req-sbm-poster' draggable="false" alt='' />
                    </div>
                    <div className='venue-req-sbm-section-wrapper'>
                        <div className='venue-req-sbm-section'>
                            <div>
                                <h4 className='venue-req-sbm-section-title'>request submitted</h4>
                                <p className='venue-req-sbm-section-description mb'>Thank you for requesting to book, a member of our team will be in touch shortly to discuss your enquiry further.</p>
                            </div>

                            <div className='venue-req-sbm-section-action'>
                                <button className='venue-req-sbm-section-submit-btn' type='button' onClick={onBackToHome}>back to homepage</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default VenueEnquire;