import axios from './axios';

const baseApiUrl = process.env.REACT_APP_API_URL;
const endPoint = '/venue-enquire';


export const saveVenueEnquire = (body) => {
    return axios.post(`${endPoint}`, body);
}

export const downloadBrochure = () => {
    return `${baseApiUrl}/download-brochure`;
}